<template>
  <div class="container">
    <publicacion-modal
      :publicacion-id="selectedPublicacionId"
    ></publicacion-modal>
    <show-paid-or-unpaid-data :paidInfo="false">
      <info>Con el plan Starter tienes acceso a las 10 últimas entradas.</info>
    </show-paid-or-unpaid-data>
    <publicaciones-filters @search="this.getPublicacionesEstatales" />
    <c-card title="Publicaciones Estatales">
      <div class="d-flex justify-content-end">
        <show-paid-or-unpaid-data>
          <json-excel
            :data="PublicacionesEstatales"
            :fields="publicacionesFields"
            name="publicaciones-estatales"
          >
            <c-primary-button> Descargar </c-primary-button>
          </json-excel>
        </show-paid-or-unpaid-data>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Fecha de publicación oficial</th>
            <th>Estado</th>
            <th>Titulo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="PublicacionEstatal in PublicacionesEstatales"
            :key="PublicacionEstatal.id"
          >
            <td>
              {{ PublicacionEstatal.fecha_publicacion_oficial_formatted }}
            </td>
            <td>{{ PublicacionEstatal.state }}</td>
            <td>{{ PublicacionEstatal.title }}</td>
            <td class="text-nowrap">
              <button
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                @click.prevent="selectedPublicacionId = PublicacionEstatal.id"
                data-bs-toggle="modal"
                data-bs-target="#publicacionMunicialModal"
                title="Ver"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import JsonExcel from "vue-json-excel3";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import Info from "@/components/Alerts/Info.vue";
import PublicacionesFilters from "@/components/PublicacionesFilters.vue";
import { all as AllPublicacionesEstatales } from "@/services/publicaciones_estatales";
import ShowPaidOrUnpaidData from "@/components/showPaidOrUnpaidData";
import PublicacionModal from "@/components/Publicaciones/PublicacionEstatalModal";
import { userPlanIsActive } from "@/services/users";

export default {
  components: {
    CCard,
    PublicacionesFilters,
    JsonExcel,
    CPrimaryButton,
    ShowPaidOrUnpaidData,
    PublicacionModal,
    Info,
  },
  data() {
    return {
      PublicacionesEstatales: [],
      selectedPublicacionId: null,
      publicacionesFields: {
        "Fecha de publicación oficial": "fecha_publicacion_oficial_formatted",
        Estado: "state",
        Giro: "giro",
        Tipo: "tipo",
        Título: "title",
        "Palabras clave": "keywords",
        Observaciones: "observations",
        "Fecha de aplicación": "fecha_entrada_en_vigor_formatted",
        link: "link",
        páginas: "pages",
      },
    };
  },
  mounted() {
    // this.getPublicacionesEstatales();
  },
  methods: {
    getPublicacionesEstatales() {
      const queryParams = this.$route.query;
      AllPublicacionesEstatales(queryParams)
        .then(async (response) => {
          const planIsActive = await userPlanIsActive();
          // TODO refactor to handle this in backend
          planIsActive
            ? (this.PublicacionesEstatales = response.data)
            : (this.PublicacionesEstatales = response.data.slice(0, 10));
        })
        .catch(() => {
          alert("No pudimos cargar las Publicaciones Estatales");
        });
    },
  },
};
</script>
