<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="publicacionMunicialModal"
    tabindex="-1"
    aria-labelledby="publicacionMunicialModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            {{ publicacion.title }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <h6>Estado:</h6>
            <p>{{ publicacion.state }}</p>
          </div>
          <div>
            <h6>Giro:</h6>
            <p>{{ publicacion.giro }}</p>
          </div>
          <div>
            <h6>Tipo:</h6>
            <p>{{ publicacion.tipo }}</p>
          </div>
          <div>
            <h6>Fecha de publicación oficial:</h6>
            <p>{{ publicacion.fecha_publicacion_oficial_formatted }}</p>
          </div>
          <div>
            <h6>Fecha de aplicación:</h6>
            <p>
              {{
                publicacion.fecha_entrada_en_vigor
                  ? publicacion.fecha_entrada_en_vigor_formatted
                  : "NA"
              }}
            </p>
          </div>
          <div>
            <h6>Palabras clave:</h6>
            <p>{{ publicacion.keywords }}</p>
          </div>
          <div>
            <h6>Link:</h6>
            <p class="d-inline-block me-2">{{ publicacion.link }}</p>
            <a
              :href="publicacion.link"
              target="_blank"
              class="btn c-bg-primary text-white d-inline-block"
              >Ver</a
            >
          </div>
          <div>
            <h6>Observaciones:</h6>
            <p>{{ publicacion.observations }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { find } from "@/services/publicaciones_estatales";
export default {
  props: {
    publicacionId: {
      required: true,
      type: String,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      publicacion: {},
    };
  },
  watch: {
    publicacionId() {
      this.publicacion = {};
      this.getPublicacion();
    },
  },
  mounted() {
    if (this.publicacionId) {
      this.getPublicacion();
    }
  },
  methods: {
    getPublicacion() {
      find(this.publicacionId).then(
        (response) => (this.publicacion = response.data)
      );
    },
  },
};
</script>
